import React , {useState} from 'react';
import emailjs from 'emailjs-com';
/* I18 */
import { useTranslation } from 'react-i18next'


const Result = () => {
    const { t } = useTranslation()
    return (
        <p className="success-message">{t('Your Message has been successfully sent. I will contact you soon.')}</p>
    )
}

function ContactForm({props , formStyle}) {
    const { t } = useTranslation()
    const [ result, showresult ] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Form Data:", e.target)
        console.log("Public Key:", process.env.REACT_APP_EMAILJS_PUBLIK_KEY)
        emailjs
        .sendForm(
            process.env.REACT_APP_EMAILJS_CONTACT_SERVICE_ID, 
            process.env.REACT_APP_EMAILJS_CONTACT_TEMPLATE_ID, 
            e.target,
            process.env.REACT_APP_EMAILJS_PUBLIK_KEY
        )
        .then((result) => {
            console.log(result.text);
            }, 
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="form-group">
                <input 
                type="text"
                name="fullname"
                placeholder={t("Your Name")}
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="email"
                name="email"
                placeholder={t("Email Address")}
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="text"
                name="phone"
                placeholder={t("Phone Number")}
                required
                />
            </div>


            <div className="form-group">
                <input 
                type="text"
                name="subject"
                placeholder={t("Subject")}
                required
                />
            </div>

            <div className="form-group">
                <textarea 
                name="message"
                placeholder={t("Your Message")}
                required
                >
                </textarea>
            </div>

            <div className="form-group">
                <button className="btn-default btn-large">{t('Submit Now')}</button>
            </div> 

            <div className="form-group">
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}
export default ContactForm;
