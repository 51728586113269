import PropTypes from "prop-types";
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';


const SEO = ( {title, description, type, url} ) => {
    return (
        <Helmet>
            {/* Standard metadata tags */}
            <title>{title} | MAIND LOGICS</title>
            <meta name='description' content={description} />
            <link rel="canonical" href={`${process.env.REACT_APP_CANONICAL_URL}${url}`}/>
            { /* End standard metadata tags */ }
            
            { /* Facebook tags */ }
            <meta property="og:title" content={`${title} | MAIND LOGICS`} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content={type} />
            <meta property="og:site_name" content={`Maind Logics - ${"Solution for contract automation, CLM and Generative AI"}`}/>
            <meta property="og:image" content="/images/logo/logo-maind-colores-525-275.png" />
            <meta property="og:image:alt" content="Maind Logics Logo" />
            <meta property="og:image:width" content="525" />
            <meta property="og:image:height" content="275" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:url" content={url} />
            <meta property="og:locale" content="es_MX"/>
            <meta property="og:locale:alternate" content="en_US" />
            { /* End Facebook tags */ }
          
            { /* Twitter tags */ }
            <meta name="twitter:card" content='summary_large_image' />

            <meta property="twitter:site" content="@MaindLogics" />
            <meta name="twitter:creator" content='Maind Logics' />
            <meta name="twitter:title"  content={`${title} | MAIND LOGICS`} />
            <meta name="twitter:description" content={description} />
            { /* End Twitter tags */ }
            </Helmet>
    )
}
// SEO.propTypes = {
//     title: PropTypes.string,
//     description: PropTypes.string,
// };

export default SEO;


