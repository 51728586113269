import React from 'react';
import Typed from 'react-typed';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from '../common/header/HeaderTwo';
// import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutOne from '../elements/about/AboutOne';
import ServiceFive from '../elements/service/ServiceFive';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import TeamTwo from '../elements/team/TeamTwo';
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';

/* I18 */
import { useTranslation } from 'react-i18next'

var BlogListData = BlogClassicData.slice(0, 3);

const PrivacyPolicy = () => {

    const { t } = useTranslation()

    return (
        <>
            <SEO title={t("Privacy Policy")}
                 description={t("Discover how we safeguard your privacy at Maind Logics. Explore our Privacy Policy to understand how we collect, use, and protect your personal information. Learn about your rights, consent mechanisms, and our commitment to maintaining the confidentiality and security of your data.")}
                 type='website'
                 url={`${process.env.PUBLIC_URL}/privacy-policy`}
            />
            <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                <HeaderTwo btnStyle="round" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h5 className="title theme-gradient-maind display-two"> <br /> {" "}
                                        <Typed
                                            strings={[
                                                "Privacy Policy"
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Terms and Conditions Area  */}
                {/* <AboutOne />                             */}
                <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-12 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h5 className="title"> <br /> {" "}
                                    <Typed className="theme-gradient-maind"
                                        strings={[
                                          "Última actualización: 30 de septiembre de 2023"
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h5>
                                <h3> Titular y Responsable del tratamiento de los Datos</h3>
            <p>
                Sistemas Lógicos de Latinoamérica SAPI de CV - Calle Cantil 141, Col. Jardines del Pedregal, Álvaro Obregón, Ciudad de México, CP. 01900.
            </p>
            <p>Correo electrónico de contacto del Titular: info@maind.io </p>
            <h3> Clases de Datos recogidos</h3>
            <p>Entre las clases de Datos Personales que recoge esta Plataforma, ya sea directamente o a través de terceros, se encuentran: ID de Usuario; nombre; apellido(s); dirección de correo electrónico; Datos de uso; cantidad de Usuarios; cantidad de sesiones; duración de la sesión; cantidad de veces que se abre la Plataforma; Datos comunicados durante el uso del servicio; username; información de la Plataforma.</p>

            <p>La información completa referente a cada categoría de Datos Personales que se recogen se proporciona en las secciones de la presente política de privacidad dedicadas a tal fin o mediante textos explicativos específicos que se muestran antes de la recogida de dichos Datos.
            Los Datos Personales podrán ser proporcionados libremente por el Usuario o, en caso de los Datos de Uso, serán recogidos automáticamente cuando se utilice esta Plataforma.</p>

            <p>
            Todos los Datos solicitados por esta Plataforma son obligatorios y la negativa a proporcionarlos podrá imposibilitar que esta Plataforma pueda proceder a la prestación del Servicio. En los casos en los que esta Plataforma indique específicamente que ciertos Datos no son obligatorios, los Usuarios serán libres de no comunicar tales Datos sin que esto tenga consecuencia alguna sobre la disponibilidad o el funcionamiento del Servicio. Los Usuarios que tengan dudas sobre qué Datos son obligatorios pueden contactar con el Titular. 
            </p>
            <p>
            El uso de Cookies - o de otras herramientas de seguimiento - por parte de esta Plataforma o por los titulares de servicios de terceros utilizados por esta Plataforma tiene como finalidad la prestación del Servicio solicitado por el Usuario, además de cualesquiera otras finalidades que se describan en el presente documento y en la Política de Cookies, en caso de estar disponible.
            El Usuario asume la responsabilidad respecto de los Datos Personales de terceros que se obtengan, publiquen o compartan a través de esta Plataforma y declara por la presente que tiene el consentimiento de dichos terceros para proporcionar dichos Datos al Titular.</p>

            <h3> Titular y Responsable del tratamiento de los Datos</h3>
            <h4>1.Modalidades de Tratamiento:</h4>
            <p>El Titular tratará los Datos de los Usuarios de manera adecuada y adoptará las medidas de seguridad apropiadas para impedir el acceso, la revelación, alteración o destrucción no autorizados de los Datos. El tratamiento de Datos se realizará mediante ordenadores y/o herramientas informáticas, siguiendo procedimientos y modalidades organizativas estrictamente relacionadas con las finalidades señaladas. Además del Titular, en algunos casos podrán acceder a los Datos ciertas categorías de personas autorizadas, relacionadas con el funcionamiento de esta Plataforma (administración, ventas, marketing, departamento jurídico y de administración de sistemas) o contratistas externos que presten servicios al Titular (tales como proveedores externos de servicios técnicos, empresas de mensajería, empresas de hosting, empresas de informática, agencias de comunicación) que serán nombrados por el Titular como Encargados del Tratamiento, si fuera necesario. Se podrá solicitar al Titular en cualquier momento una lista actualizada de dichas personas.</p>
           

            <h4>2. Base jurídica del Tratamiento:</h4>
            <p>El Titular podrá tratar los Datos Personales del Usuario, si se cumple una de las siguientes condiciones:</p>
            <ul>
                <li>
                Cuando los Usuarios hayan dado su consentimiento para una o más finalidades específicas. Aviso: Al amparo de varias legislaciones diferentes, el Titular podrá estar autorizado a tratar los Datos Personales hasta que el Usuario se oponga a ello (“opción de no participación”), sin necesidad de consentimiento o de cualquier otra base jurídica. Sin embargo, esto no será aplicable cuando el tratamiento de los Datos Personales esté sujeto a la normativa europea en materia de protección de Datos Personales;
                </li>
                <li>Cuando la obtención de Datos sea necesaria para el cumplimiento de un contrato con el Usuario y/o cualquier otra obligación precontractual del mismo;</li> 
                <li>Cuando el tratamiento sea necesario para el cumplimiento de una obligación legal de obligado cumplimiento por parte del Usuario</li>
                <li>Cuando el tratamiento esté relacionado con una tarea ejecutada en interés público o en el ejercicio de competencias oficiales otorgadas al Titular;</li>
                <li>Cuando el tratamiento sea necesario con el fin de un interés legítimo perseguido por el Titular o un tercero.</li>
            </ul>
   <p>En cualquier caso, el Titular está a su disposición para definir las bases jurídicas específicas que se aplican al tratamiento y en particular, si la obtención de los Datos Personales es un requisito contractual o estatutario o un requisito necesario para formalizar un contrato.</p>

   <h4>3. Lugar:</h4>
   <p>Los Datos se tratan en las oficinas del Titular, así como en cualquier otro lugar en el que se encuentren situadas las partes implicadas en dicho proceso de tratamiento.
Dependiendo de la localización de los Usuarios, las transferencias de Datos pueden implicar la transferencia de los Datos de los Usuarios a otro país diferente al suyo propio. Para más información sobre el lugar de tratamiento de dichos Datos transferidos, los Usuarios podrán consultar la sección que contiene los detalles sobre el tratamiento de los Datos Personales.</p>

<p>Los Usuarios también tendrán derecho a conocer las bases legales de las transferencias de Datos a otro país fuera de la Unión Europea o a cualquier organismo internacional que se rija por el Derecho Público Internacional o que esté formado por dos o más países, como la ONU, y además conocer las medidas de seguridad tomadas por el Titular para salvaguardar sus Datos.</p>

<p>
En caso de que tuviera lugar dicha transferencia de Datos, los Usuarios podrán obtener más información consultando las secciones relevantes del presente documento o solicitándola al Titular, a través de la información de contacto que aparece en la sección de contacto.</p>


<h4>4. Período de conservación:</h4>
<p>Los Datos Personales serán tratados y conservados durante el tiempo necesario y para la finalidad por la que han sido recogidos.</p>
<p>Por lo tanto:</p>
<p>
Los Datos Personales recogidos para la formalización de un contrato entre el Titular y el Usuario deberán conservarse como tales hasta en tanto dicho contrato se haya formalizado por completo.</p>

<p>Los Datos Personales recogidos en legítimo interés del Titular deberán conservarse durante el tiempo necesario para cumplir con dicha finalidad. Los Usuarios pueden encontrar información específica relacionada con el interés legítimo del Titular consultando las secciones relevantes del presente documento o contactando con el Titular.</p>

<p>El Titular podrá conservar los Datos Personales durante un periodo adicional cuando el Usuario preste su consentimiento a tal tratamiento, siempre que dicho consentimiento siga vigente. Además, el Titular estará obligado a conservar Datos Personales durante un periodo adicional siempre que se precise para el cumplimiento de una obligación legal o por orden que proceda de la autoridad.</p>

<p>Una vez terminado el período de conservación, los Datos Personales deberán eliminarse. Por lo tanto, los derechos de acceso, modificación, rectificación y portabilidad de datos no podrán ejercerse una vez haya expirado dicho periodo.</p>

<h4>Finalidad del Tratamiento de los Datos recogidos</h4>

<p>Los Datos relativos al Usuario son recogidos para permitir al Titular prestar su Servicio, cumplir sus obligaciones legales, responder a solicitudes de ejecución, proteger sus derechos e intereses (o los de sus Usuarios o terceros), detectar cualquier actividad maliciosa o fraudulenta, así como para las siguientes finalidades: Acceso a las cuentas de servicios de terceros, Servicios de plataforma y de alojamiento, Contactar con el Usuario, Estadísticas, Hosting e infrastructura de backend, Registro y autenticación y Registro y autenticación prestados directamente por esta Plataforma.</p>

<p>Los Usuarios pueden encontrar información específica los Datos personales utilizados para cada finalidad en la sección “Información detallada del Tratamiento de los Datos Personales”.</p>

<h5>1. Permisos de Facebook/Google/Apple solicitados por esta Plataforma:</h5>
<p>Esta Plataforma podrá solicitar permisos de Facebook/Google/Apple que le permitan realizar acciones en la cuenta de Facebook/Google/Apple del Usuario y recuperar información de ésta, incluyendo Datos Personales. Este servicio permite a esta Plataforma conectarse con la cuenta del Usuario en la red social Facebook/Google/Apple, proporcionada por Facebook Inc; Google, Inc, y/o Apple, Inc.</p>
<p>Para obtener más información acerca de los siguientes permisos, diríjanse a la documentación de los permisos de Facebook/Google/Apple y a la política de privacidad de Facebook/Google/Apple según sea el caso.
Los permisos solicitados son los siguientes: Correo electrónico de contacto y Información básica.</p>

<h5>2. Información detallada del Tratamiento de los Datos Personales:</h5>
<p>Los Datos Personales se recogen para las siguientes finalidades y utilizando los siguientes servicios:</p>
    <ul>
        <li>
            Acceso a las cuentas de servicio de terceros.
        <ul>
            <li>
                Este tipo de servicios permiten a esta Plataforma acceder a los Datos de tus cuentas en servicios de terceros y realizar accciones con estos.
            </li>
            <li>
                Estos servicios no se activan automáticamente sino que requieren la autorización expresa del Usuario.
            </li>
        </ul>
        </li>
        <li>
            Contactar con el Usuario.
        <ul>
            <li>
                Al rellenar el formulario de contacto con sus Datos, el Usuario autoriza a esta Plataforma a utilizar dichos datos para responder a solicitudes de información, de presupuestos o de cualquier otro tipo que esté indicado en el encabezamiento del formulario. Datos Personales tratados: apellido(s); dirección de correo electrónico; nombre.
            </li>
            <li> 
                Al registrarse en la lista de correo o el newsletter, la dirección de correo electrónico del Usuario será incluida automáticamente en una lista de contactos a los que se les podrán remitir mensajes de correo electrónico con información comercial o promocional relativa a esta Plataforma. Su dirección de correo electrónico también podrá ser incluida en esta lista a raíz de registrarse en esta Plataforma o tras realizar una compra. Datos Personales tratados: apellido(s); dirección de correo electrónico; nombre.</li>
        </ul>
        </li>

        <li>
            Hosting e infraestructura de backend.
            <ul>
                <li>
                    Este tipo de servicios tienen por finalidad el alojamiento de Datos y archivos para permitir que esta Plataforma funcione y sea distribuida, así como para proporcionar una infrastructura lista para operar que permita poner en marcha diversas funcionalidades o partes de esta Plataforma.
                </li>
                <li>
                    Algunos servicios de los enumerados más adelante, en su caso, pueden funcionar mediante servidores geográficamente distribuidos, lo que dificulta la determinación de la localización exacta en la que se almacenan los Datos personales.
                </li>
                <li>
                    Amazon Web Services (AWS) (Amazon Web Services, Inc.):
                    <ul>
                        <li>
                            Amazon Web Services (AWS) es un servicio de hosting y backend prestado por Amazon Web Services, Inc.
                        </li>
                        <li>
                            Datos Personales tratados: distintas clases de Datos, según se especifica en la Política de Privacidad del servicio.
                
                        </li>
                        <li>
                        Lugar de tratamiento: EE.UU. 
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        <li>
            Registro y autenticación.
            <ul>
                <li>
                Al registrarse o autenticarse, el Usuario permite que esta Plataforma le identifique y le dé acceso a los servicios dedicados.
                </li>
                <li> 
                Dependiendo de lo indicado a continuación, los servicios de registro y autenticación podrán ser prestados por la asistencia de terceros. En tal caso, esta Plataforma podrá acceder a algunos Datos almacenados por el servicio de terceros utilizado para el registro o autenticación.
                </li>
                <li>
                    Es posible que algunos de los servicios enumerados a continuación también recojan Datos personales para fines de segmentación y elaboración de perfiles; para obtener más información, le rogamos que consulte la descripción de cada servicio.
                </li>
                <li>
                Registro directo y elaboración de perfiles (esta Plataforma)
                    <ul>
                        <li>
                            Al registrarse o autenticarse, el Usuario permite que esta Plataforma le identifique y le dé acceso a los servicios dedicados. El Titular también podrá tratar Datos recogidos cuando los Usuarios se registren o autentifiquen para fines de segmentación y elaboración de perfiles; para obtener más información, los Usuarios pueden contactar con el Titular mediante los datos de contacto indicados en el presente documento.
                        </li>
                        <li>
                            Datos Personales tratados: apellido(s); dirección de correo electrónico; nombre.
                        </li>
                    </ul>
                </li>
                <li>
                    Facebook Authentication (Meta Platforms Ireland Limited):
                    <ul>
                        <li>
                            Facebook Authentication es un servicio de registro y autenticación prestado por Meta Platforms Ireland Limited y conectado a la red social Facebook.
                        </li>
                        <li>
                            Datos Personales tratados: distintas clases de Datos, según se especifica en la Política de Privacidad del servicio; Rastreadores.
                        </li>
                        <li>
                        Lugar de tratamiento: Irlanda.
                        </li>
                    </ul>
               </li>
               <li>
                Permisos de Gmail para acceder a los Datos de los Usuarios (complemento OAuth) (Google Ireland Limited):
                    <ul>
                        <li>
                           Para prestar este servicio, esta Plataforma utiliza ámbitos restringidos para Gmail que dan acceso a los Datos de los Usuarios relativos a este servicio de Google (según lo indicado más abajo). Google Ireland Limited proporciona este acceso bajo condiciones estrictas. A continuación, se indica lo que esto supone para dichos Datos de Usuarios:
                           <ul>
                                <li>
                                • El uso de los Datos de Usuarios se limita a la puesta a disposición o mejora de funcionalidades dirigidas a los Usuarios. Los Datos de los Usuarios no se utilizarán para enviar anuncios, incluyendo el retargeting, la publicidad personalizada o basada en intereses.
                                </li>
                                <li>
                                    Solo se transmitirán Datos de Usuarios a terceros si es necesario, con el fin de proporcionar o mejorar funcionalidades dirigidas a los Usuarios y en la medida de lo necesario para cumplir con la legislación aplicable o como parte de una fusión, adquisición o venta de activos con notificación a los Usuarios.
                                 </li>
                                  <li>
                                Ninguna persona leerá los Datos a menos qué: el Titular haya obtenido el consentimiento efectivo para mensajes específicos; sea necesario por motivos de seguridad y/o para cumplir con la legislación aplicable, o; el uso esté limitado a operaciones internas y los Datos (incluyendo derivaciones) se hayan agregado y anonimizado.
                                 </li>
                           </ul>
                        </li>
                        <li> Datos Personales tratados: Acceso pleno.</li>
                        <li> Lugar de tratamiento: Irlanda</li>
                    </ul>
                  <li>
                  Iniciar sesión con Apple (Apple Inc.)
                  <ul>
                    <li>
                        Iniciar sesión con Apple es un servicio de registro y autenticación prestado por Apple Inc. En los casos en los que se exige a los Usuarios que faciliten su dirección de correo electrónico, Iniciar sesión con Apple podrá generar una dirección privada de remisión en nombre de los Usuarios que reenvíe automáticamente mensajes a su cuenta de correo electrónico personal verificada - con lo que se ocultará su dirección de correo electrónico real al Titular.
                    </li>
                    <li>
                    Datos Personales tratados: apellido(s); dirección de correo electrónico; nombre.
                    </li>
                    <li>
                    Lugar de tratamiento: EE.UU.
                    </li>
                  </ul>
                    </li> 
                </li>
            </ul>
        </li>
        <li>
        Registro y Autenticación Prestados Directamente por esta Plataforma:
        <ul>
            <li>
            Al registrarse o autenticarse, el Usuario permite que esta Plataforma le identifique y le dé acceso a los servicios dedicados. Los Datos personales se recogen y almacenan únicamente para fines de registro o identificación. Solo se recogen los Datos necesarios para la prestación del servicio solicitado por los Usuarios.
            </li>
            <li>
                El Usuario se registra rellenando el formulario de registro y proporcionando sus Datos Personales directamente a esta Plataforma.
            </li>
            <li>
            Datos Personales tratados: apellido(s); dirección de correo electrónico; nombre.
            </li>
        </ul>
        </li>
    </ul>
  
<h4>Los derechos de los Usuarios</h4>
<p>Los Usuarios podrán ejercer ciertos derechos con respecto al tratamiento de Datos por parte del Titular.</p>
<p>En particular, los Usuarios tienen derecho a hacer lo siguiente, en la medida en que lo permita la ley:</p>
<p>Retirar su consentimiento en cualquier momento. Los Usuarios tienen derecho a retirar su consentimiento cuando lo hubieran concedido con anterioridad para el tratamiento de sus Datos Personales.</p>
<p>Objeción al tratamiento de sus Datos. Los Usuarios tienen derecho a oponerse al tratamiento de sus Datos si dicho tratamiento se lleva a cabo con arreglo a una base jurídica distinta del consentimiento. Para más información pueden dirigirse a la sección correspondiente más adelante.</p>
<p>Acceso a sus Datos. Los Usuarios tienen derecho a conocer si sus Datos serán tratados por el Titular, a obtener información sobre ciertos aspectos del tratamiento, además de obtener una copia de los Datos objeto del tratamiento.</p>
<p>Verificar y solicitar la modificación. Los Usuarios tienen derecho a verificar la exactitud de sus Datos y solicitar que los mismos se actualicen o corrijan.
Limitar el tratamiento de sus Datos. Los Usuarios tienen derecho a limitar el tratamiento de sus Datos. En ese supuesto, el Titular solo tratará sus Datos con la finalidad de almacenarlos.</p>
<p>Borrar o eliminar los Datos Personales. Los Usuarios tienen derecho a obtener la supresión de sus Datos por parte del Titular.
Recibir sus Datos y transferirlos a otro responsable. Los Usuarios tienen derecho a recibir sus Datos en un formato estructurado, de uso común y lectura mecánica y, si fuera técnicamente posible, a que se transmitan los mismos a otro responsable sin ningún impedimento.</p>
<p>Poner una reclamación. Los Usuarios tienen derecho a poner una reclamación ante la autoridad competente en materia de protección de datos de carácter personal.</p>
<h5>1. Detalles sobre el derecho de oposición al tratamiento:</h5>
<p>Cuando el tratamiento de los Datos Personales sea de interés público, en el ejercicio de competencias oficiales otorgadas al Titular o con motivo de un interés legítimo del Titular, los Usuarios podrán oponerse a dicho tratamiento explicando un motivo con relación a su situación particular para justificar su objeción.</p>

<p>Los Usuarios deben saber, sin embargo, que en caso de que sus Datos Personales sean tratados con fines de marketing directo, pueden oponerse en cualquier momento a tal tratamiento, de forma gratuita y sin necesidad de justificación. Cuanto el Usuario se oponga al tratamiento para fines de marketing directo, los Datos Personales no podrán continuar siendo tratados para tales fines. Para saber si los Datos Personales de los Usuarios están siendo tratados por el Titular para fines de marketing directo, los Usuarios deberán consultar las secciones relevantes del presente documento.</p>

<h5>2. Cómo ejercer estos derechos:</h5>
<p>Cualquier solicitud para ejercer los derechos del Usuario puede dirigirse al Titular a través de los datos de contacto facilitados en el presente documento. Dichas solicitudes serán tramitadas por el Titular sin coste alguno y el Titular responderá a ellas tan pronto como le sea posible y siempre dentro del plazo de un mes, proporcionando a los Usuarios la información exigida por la ley. El Titular comunicará cualquier rectificación o supresión de Datos Personales o limitación del tratamiento, a cada destinatario, en su caso, al que se le hayan comunicado los Datos Personales, salvo que sea imposible o exija un esfuerzo desproporcionado. A solicitud de los Usuarios, el Titular les informará sobre dichos destinatarios.</p>

<h5>3. Información adicional sobre la recogida de Datos y su tratamiento:</h5>
<p>Defensa jurídica:</p>
<p>Los Datos Personales del Usuario podrán ser utilizados para la defensa jurídica del Titular ante un tribunal o en las fases judiciales previas a un posible pleito derivado del uso inapropiado de esta Plataforma o de los Servicios relacionados.</p>
<p>El Usuario declara conocer que el Titular puede ser requerido por las autoridades públicas a fin de revelar Datos Personales.</p>

<h5>4. Información adicional acerca de los Datos Personales del Usuario:</h5>
<p>Además de las informaciones contenidas en esta política de privacidad, esta Plataforma podrá proporcionar al Usuario información adicional y contextual relativa a Servicios específicos o a la recogida y tratamiento de los Datos Personales.</p>

<h5>5. Log del sistema y mantenimiento:</h5>
<p>Por motivos relativos al funcionamiento y mantenimiento, esta Plataforma y cualquier otro servicio, proporcionado por terceros, que se utilice, podrá recoger un registro del sistema; es decir, archivos que registren la interacción con esta Plataforma y que puedan contener Datos Personales, tales como la dirección IP del Usuario.</p>

<h5>6. Información no contenida en esta política de privacidad:</h5>
<p>Se podrá solicitar en cualquier momento información adicional sobre la recogida y el tratamiento de los Datos Personales al Titular. La información de contacto se indica al inicio del presente documento.</p>

<h5>7. Cómo se procesan las solicitudes “Do Not Track”:</h5>
<p>Esta Plataforma no permite solicitudes “Do Not Track”.
Para determinar si cualquiera de los servicios de terceros que utiliza acepta solicitudes “Do Not Track”, por favor lea sus políticas de privacidad.
</p>

<h5>8. Modificación de la presente política de privacidad:</h5>
<p>El Titular se reserva el derecho de modificar esta política de privacidad en cualquier momento, notificándolo a los Usuarios a través de esta página y, a ser posible, a través de esta Plataforma y/o de ser técnica y legalmente posible notificando directamente a los Usuarios, en caso de que el Titular cuente con la información de contacto necesaria a tal fin. Se recomienda encarecidamente que revisen esta página con frecuencia, tomando como referencia la fecha de la última actualización indicada al final de la página.
</p>
<p>
En el caso de que los cambios afectasen a las actividades de tratamiento realizadas en base al consentimiento del Usuario, el Titular deberá obtener, si fuera necesario, el nuevo consentimiento del Usuario.</p>

<h4>Definiciones y referencias legales</h4>
<h5>1. Datos Personales (o Datos):</h5> 
<p>Constituye un dato personal cualquier información que, directa, indirectamente o en relación con otra información – incluyendo un número de identificación personal – permita identificar a una persona física.</p>
<h5>2. Datos de Uso:</h5> <p>Las informaciones recogidas de forma automática por esta Aplicación (o por servicios de terceros utilizados por esta Aplicación), podrán incluir: las direcciones IP o nombres de dominio de los ordenadores utilizados por el Usuario que se conecte a esta Aplicación, las direcciones URI (Uniform Resource Identifier), la hora de la solicitud, el método utilizado para realizar la solicitud al servidor, las dimensiones del archivo obtenido en respuesta, el código numérico indicando el estado de la respuesta del servidor (resultado satisfactorio, error, etc.), el país de origen, las características del navegador y del sistema operativo utilizados por el visitante, las diversas coordenadas temporales de la visita (por ejemplo, el tiempo de permanencia en cada una de las páginas) y los detalles relativos al itinerario seguido dentro de la Aplicación, con especial referencia a la secuencia de páginas consultadas, a los parámetros relativos al sistema operativo y al entorno informático del Usuario.</p> 
<h5>3. Usuario: </h5>
<p>El individuo que utilice esta Aplicación, quien, a menos que se indique lo contrario deberá coincidir con el Interesado.</p> 
<h5>4. Interesado:</h5>
<p> La persona física a la que se refieren los Datos Personales.</p>
<h5>5. Encargado del Tratamiento (o Encargado): </h5>
<p> La persona física o jurídica, administración pública, agencia o cualquier otra institución, que procese los Datos Personales en nombre del Responsable del Tratamiento, descrita en la presente política de privacidad.</p>
<h5>6. Responsable del Tratamiento (o Titular):</h5>
<p>La persona física o jurídica, administración pública, agencia o cualquier otra institución, que actuando en solitario o conjuntamente con otras, determine las finalidades y las medidas del tratamiento de los Datos Personales, incluyendo las medidas de seguridad relativas al funcionamiento y al uso de esta Aplicación. A menos que se especifique lo contrario, el Responsable del Tratamiento es el Titular de esta Aplicación.</p> 
<h5>7. Esta Plataforma:</h5> 
<p>El medio a través del cual se han recogido y tratado los Datos Personales del Usuario.</p>
<h5>8. Servicio: </h5> 
<p>El servicio proporcionado por esta Aplicación, tal y como se describe en las definiciones y referencias legales (en caso de estar disponibles) y en esta página o aplicación.</p>
<h5>9. Unión Europea (o UE):</h5> 
<p>A menos que se indique lo contrario, todas las referencias hechas a la Unión Europea en el presente documento incluyen todos los actuales Estados miembros de la Unión Europea y del Espacio Económico Europeo. 
</p>
<h5>10. Cookie:</h5>
<p>Las Cookies son Rastreadores que consisten en pequeñas cantidades de datos almacenados en el navegador del Usuario.</p> 
<h5>11. Rastreador:</h5>
<p>Rastreador designa cualquier tecnología - p.ej. Cookies, identificadores únicos, balizas web, scripts incrustados, etiquetas de entidad y creación de huella digital - que permite rastrear a los Usuarios, por ejemplo, accediendo a información o almacenándola en el dispositivo del Usuario.</p> 

<h5>12. Acceso Pleno:</h5>
<p> Acceso pleno a la cuenta, incluyendo la eliminación definitiva de hilos y mensajes. </p>

<h5>13. Información Legal:</h5> 
<p>Esta política de privacidad se ha redactado en base a las provisiones de múltiples legislaciones, incluyendo los Arts. 13 y14 del Reglamento (UE) 2016/679 (Reglamento general de protección de datos). Esta política de privacidad se refiere sólo a esta Aplicación, a menos que se indique lo contrario en el presente documento.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
                {/* End Terms and Conditions Area  */}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default PrivacyPolicy;

