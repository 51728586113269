import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from "react-ga4";

/* I18n */
import './i18n.js'

// If you are using react-helmet-async on server side
const helmetContext = {};
ReactGA.initialize("G-VEV74GTFKB")

ReactDOM.render(
  <React.StrictMode context={helmetContext}>
    <HelmetProvider>
      <Suspense fallback="loading">
        <App />
      </Suspense>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
