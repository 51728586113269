import React , {useState} from 'react';
import emailjs from 'emailjs-com';

/* I18 */
import { useTranslation } from 'react-i18next'

const Result = () => {
    const { t } = useTranslation()
    return (
        <p className="success-message">{t('Your Message has been successfully sent. We will contact you soon.')}</p>
    )
}

function BookDemoForm({props , formStyle}) {

    const { t } = useTranslation()

    const message_generative_ai = t("Hello,\nI am interested in booking a call to know more about your tailored-made generative AI services. Please schedule a convenient time for the demo. I am excited to learn more about how your AI solutions can be customized to meet my specific needs and how they can enhance my operations. \nThank you!")
    const message_autodoc_platform = t("Hello,\nI am interested in booking a demo of your contract automation platform. Please schedule a convenient time for the demo. I am excited to learn more about your services and how they can benefit my legal processes. \nThank you!")
   
    const [result, showresult ] = useState(false);
    const [subject, setSubject] = useState(t("Subject"));
    const [message, setMessage] = useState(t("Your message"));

    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Form Data:", e.target)
        console.log("Public Key:", process.env.REACT_APP_EMAILJS_PUBLIK_KEY)
        emailjs
        .sendForm(
            process.env.REACT_APP_EMAILJS_CONTACT_SERVICE_ID, 
            process.env.REACT_APP_EMAILJS_BOOK_DEMO_TEMPLATE_ID, 
            e.target,
            process.env.REACT_APP_EMAILJS_PUBLIK_KEY
        )
        .then((result) => {
            console.log(result.text);
            }, 
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        showresult(true);
    };


    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
               <div className="row">
                <div className="text-center">
                    <p>{t('Choose your preferred demo:')}</p>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="checkbox1" name="checkbox1" onChange={(e) => {
                                                                                                        if (e.target.checked) {
                                                                                                           setSubject(t('Request for Tailored-Made Generative AI Demo'))
                                                                                                           setMessage(message_generative_ai)
                                                                                                        }
                                                                                                    }} />
                                <label className="form-check-label" htmlFor="checkbox1"> Tailored-Made Generative AI Demo</label>
                            </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="checkbox2" name="subject" onChange={(e) => {
                                                                                                        if (e.target.checked) {
                                                                                                            setSubject(t('Request for Contract Automation Platform Demo'))
                                                                                                           setMessage(message_autodoc_platform)
                                                                                                        }
                                                                                                    }}  />
                            <label className="form-check-label" htmlFor="checkbox2">Contract Automation Platform Demo</label>
                        </div>
                    </div>
                </div>
        </div>
             <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <input 
                        type="text"
                        name="fullname"
                        placeholder={t("Your Name")}
                        required
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <input 
                        type="text"
                        name="company"
                        placeholder={t("Company Name")}
                        required
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <input 
                        type="email"
                        name="email"
                        placeholder={t("Business Email Address")}
                        required
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                            <input 
                            type="text"
                            name="phone"
                            placeholder={t("Phone Number")}
                            required
                            />
                    </div>
                </div>
            </div>

            <div className='row'>
            <div className="form-group">
                <input 
                type="text"
                name="subject"
                value={subject}
                placeholder={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
                />
            </div>
            </div>


            <div className='row'>
            <div className="form-group">
                <textarea 
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                name="message"
                placeholder={message}
                required
                >
                </textarea>
            </div>
            </div>

       
        
            <div className="row">
                <div className="form-group text-center">
                    <button className="btn-default btn-large">{t('Reserve Now')}</button>
                </div> 
            </div>
            <div className="form-group">
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}
export default BookDemoForm;
