import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";

import { t } from 'i18next';

//Doc Automation
const dataList = [
    {
        image: '/images/service/esignature.png',
        title: 'E-Signature',
        description: 'Our platform seamlessly integrates E-signature, streamlining the document signing process.'
    },
    {
        image: '/images/service/eventreminders.png',
        title: 'Event Reminders',
        description: 'Automatically sync critical contract dates to your Google Calendar with reminders.'
    }
]

//Add-ons
const dataList2 = [
    {
        image: '/images/service/doclibrary.png',
        title: 'Doc Library',
        description: 'Our platform features a document repository that is hosted on AWS to store all of your documents.'
    },
    {
        image: '/images/service/clm.png',
        title: 'CLM',
        description: 'Automate workflows for approvals, notifications, and requests ensuring a streamlined, error-free process.'
    }
]

//Generative AI
const dataList3 = [
    {
        image: '/images/service/buildailawyer.png',
        title: 'Build your AI Lawyer',
        description: 'We customize AI for lawyers. Enhance legal research, automation, and efficiency with our tailored language models.'
    },
    {
        image: '/images/service/bespoke.png',
        title: 'Bespoke AI',
        description: 'Our tailored service is designed to provide lawyers with custom-built, large language models that are finely tuned to meet their unique needs.'
    },
    {
        image: '/images/service/becomeailawyer.png',
        title: 'Become an AI Lawyer',
        description: 'Our state-of-the-art generative AI, we equip legal professionals with a powerful tool that can draft legal documents and streamline their workflow.'
    }
]

const TabThree = () => {
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30 align-items-center">
                                <div className="col-lg-12">
                                    <div className="rn-default-tab style-three">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        {/* <button>Stategy</button> */}
                                                        <button>Doc Automation</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        {/* <button>Development</button> */}
                                                        <button>Add-ons</button>
                                                    </div>   
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        {/* <button>Reporting</button> */}
                                                        <button>Generative AI</button>
                                                    </div>   
                                                </Tab>
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <div className="row align-items-center row--30">
                                                            <div className="col-lg-4">
                                                                <div className="section-title">
                                                                    {/* <h4 className="title">Do you <span style={{ color: '#348cd1' }}>MAIND</span>?</h4> */}
                                                                    <h4 className="title">Do you mAInd?</h4>
                                                                    {/* <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Libero ea sint nihil corrupti! Harum nemo eius odio.</p> */}
                                                                    <p>{t('Our platform automates contract creation with client-tailored templates and smart forms, enabling independent generation of pre-approved legal documents by sales and commercial teams.')}</p>
                                                                    {/* <div className="read-more">
                                                                        <a className="btn-default btn-small" href="#">Read More</a>
                                                                    </div> */}
                                                                        <div className="read-more">
                                                                        {/* <a className="btn-default btn-small" href="#">Book a demo</a> */}
                                                                        <Link className="btn-default btn-medium btn-icon" to="/book-a-demo">{t('Book a Demo')} <i className="icon"><FiArrowRight /></i></Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                                                                <div className="row row--15 mt_dec--30 service-wrapper">
                                                                    {dataList.map( (val , i) => (
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                                                            <div className="card-box card-style-1 text-start">
                                                                                <div className="inner">
                                                                                    <div className="image">
                                                                                        <Link to="#service">
                                                                                            <img src={`${val.image}`} alt="card Images" />
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="content">
                                                                                        <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{__html: t(val.title)}}></Link></h4>
                                                                                        <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: t(val.description)}}></p>
                                                                                        {/* <Link className="btn-default btn-small" to="#service">Read More</Link> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            
                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <div className="row align-items-center row--30">
                                                            <div className="col-lg-4">
                                                                <div className="section-title">
                                                                    <h4 className="title">{t('Tailor-made to your needs')}</h4>
                                                                    <p>{t('Our platform offers complete customization to meet your specific needs, spanning from basic forms to advanced technological solutions.')}</p>
                                                                    {/* <div className="read-more">
                                                                        <a className="btn-default btn-small" href="#">Read More</a>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                                                                <div className="row row--15 mt_dec--30 service-wrapper">
                                                                    {dataList2.map( (val , i) => (
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                                                            <div className="card-box card-style-1 text-start">
                                                                                <div className="inner">
                                                                                    <div className="image">
                                                                                        <Link to="#service">
                                                                                            <img src={`${val.image}`} alt="card Images" />
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="content">
                                                                                        <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{__html: t(val.title)}}></Link></h4>
                                                                                        <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: t(val.description)}}></p>
                                                                                        {/* <Link className="btn-default btn-small" to="#service">Read More</Link> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                            <TabPanel>
                                                <div className="rn-tab-content">
                                                    <div className="inner">
                                                        <div className="row align-items-center row--30">
                                                            <div className="col-lg-12">
                                                                <div className="row row--15 mt_dec--30 service-wrapper">
                                                                    {dataList3.map( (val , i) => (
                                                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                                                            <div className="card-box card-style-1 text-start">
                                                                                <div className="inner">
                                                                                    <div className="image">
                                                                                        <Link to="#service">
                                                                                            <img src={`${val.image}`} alt="card Images" />
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className="content">
                                                                                        <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{__html: t(val.title)}}></Link></h4>
                                                                                        <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: t(val.description)}}></p>
                                                                                        {/* <Link className="btn-default btn-small" to="#service">Read More</Link> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default TabThree
