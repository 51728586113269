import React from 'react';

/* I18 */
import { useTranslation } from 'react-i18next'

const LanguageMode = () => {

      // ** Hooks
  const { i18n } = useTranslation()

    // ** Vars
  const langFlag = {
    en: 'us',
    sp: 'mx'
  }
  const switchLanguage = () => {
    console.log("Change current language", i18n.language)
    const newLanguage = i18n.language === 'en' ? 'sp' : 'en';
    console.log("New language:", newLanguage)
    
    const newFlag = i18n.language === 'en' ? 'mx' : 'us';
    console.log("New Flag:", newFlag)
    
    i18n.changeLanguage(newLanguage)
    console.log("New Language updated", i18n.language)
    // const elem = document.getElementById("flag");
    // console.log("Elenment:", elem)
    // elem.src = `${process.env.PUBLIC_URL}/images/flags/${newFlag}.png`
    };

  return (
      <button style={{borderRadius: '50%', width:"40px", height:"40px", padding: 0, overflow: 'hidden'}} onClick={switchLanguage}>
        <img id="flag"
          style={{ width: '100%', height: '100%',  objectFit: 'cover' }}
          src={`${process.env.PUBLIC_URL}/images/flags/${langFlag[i18n.language]}.png`}
          alt="MX flag"
        />
        </button>
  )
};

export default LanguageMode;