import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
import HeaderTwo from '../../common/header/HeaderTwo';

/* I18 */
import { useTranslation } from 'react-i18next'

const Contact = () => {
    const { t } = useTranslation()
    return (
        <>
            <SEO title={t("Contact Us")}
                 description={t("We are here to answer your questions about our platform! You can contact us by email or through this contact form.")}
                 type='website'
                 url = {`${process.env.PUBLIC_URL}/contact`}
                  />
            <Layout>
                {/* <BreadcrumbOne 
                    title="We help our clients to empower their business to thrive with effortless contract drafting."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Contact"
                /> */}
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = {t("Let us know how we can help you!")}
                                        title = {t("Let us know how we can help you!")}
                                        description = ""
                                    />
                                </div>
                            </div>
                            <ContactOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default Contact;