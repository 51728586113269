import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import BookDemoOne from "./BookDemoOne";
import HeaderTwo from '../../common/header/HeaderTwo';
/* I18 */
import { useTranslation } from 'react-i18next'

const BookDemo = () => {
    const { t } = useTranslation()
    return (
        <>
            <SEO title={t("Book a Demo")}
                 description={t("Interested in our platform? Schedule an appointment with one of our specialists today!")}
                 type='website' 
                 url = {`${process.env.PUBLIC_URL}/book-a-demo`}
            />

            <Layout>
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = ""
                                        title = "Reserve your spot for a mAInd-blowing demo!"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <BookDemoOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default BookDemo;