import i18n from "i18next";
import { initReactI18next } from "react-i18next";


// Importing translation files

import translation_sp from './assets/locales/sp.json'
import translation_en from './assets/locales/en.json';


//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translation_en,
  },
  sp: {
    translation: translation_sp,
  },
};

//i18N Initialization

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng:"en", //default language
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;