import React from 'react';
import Typed from 'react-typed';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTwo from '../common/header/HeaderTwo';
// import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutOne from '../elements/about/AboutOne';
import ServiceFive from '../elements/service/ServiceFive';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import TeamTwo from '../elements/team/TeamTwo';
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';

/* I18 */
import { useTranslation } from 'react-i18next'

var BlogListData = BlogClassicData.slice(0, 3);

const TermsConditions = () => {

    const { t } = useTranslation()

    return (
        <>
            <SEO title={t("Terms and Conditions")}
                 description={t("Explore our Terms and Conditions to understand the guidelines, rules, and obligations when using our services. Learn about user responsibilities, privacy policies, and legal agreements for a transparent and secure experience with Maind Logics.")}
                 type='website'
                 url={`${process.env.PUBLIC_URL}/terms-and-conditions`}
            />
            <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                <HeaderTwo btnStyle="round" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h5 className="title theme-gradient-maind display-two"> <br /> {" "}
                                        <Typed
                                            strings={[
                                                "Terms and Conditions"
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Terms and Conditions Area  */}
                {/* <AboutOne />                             */}
                <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-12 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h5 className="title"> <br /> {" "}
                                    <Typed className="theme-gradient-maind"
                                        strings={[
                                          "Última actualización: 30 de septiembre de 2023"
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h5>
                                <p>
              A los Usuarios (como se define más adelante), les informamos que los siguientes Términos y Condiciones les son aplicables por el simple uso o acceso a cualquiera de las páginas, aplicaciones web y móviles, softwares y, aplicaciones en general, que integran el Sitio de maind.io (en adelante y, conjunta e indistintamente, el “Sitio”), por lo que el uso conlleva la aceptación y cumplimiento de los mismos. En el caso de que no esté de acuerdo con los presentes Términos y Condiciones deberá abstenerse de acceder o utilizar el Sitio.
              Sistemas Lógicos de Latinoamérica, S.A.P.I., de C.V., y/o sus subsidiarias, controladoras, partes relacionadas y/o afiliadas (en adelante y, conjunta e indistintamente, la “Empresa”) se reservan el derecho de modificar discrecionalmente el contenido del Sitio en cualquier momento, sin necesidad de previo aviso.
              El Usuario, entendido como aquella persona que realiza el uso o accede al Sitio, mediante equipo de cómputo y/o cualquier equipo de comunicación o dispositivo (en adelante el “Usuario”), conviene en no utilizar dispositivos, software, o cualquier otro medio tendiente a interferir tanto en las actividades y/u operaciones del Sitio, en las bases de datos y/o información que se contenga en el mismo.
            </p>
            <h3> 1. USO Y RESTRICCIONES.</h3>
            <p>El acceso o utilización del Sitio expresan la adhesión plena y sin reservas del Usuario a los presentes Términos y Condiciones. A través del Sitio, el Usuario se servirá, contratará y/o utilizará diversos servicios y contenidos (los “Servicios y Contenidos”), puestos a disposición por la Empresa. La Empresa tendrá el derecho a negar, restringir o condicionar al Usuario el acceso al Sitio, total o parcialmente, a su entera discreción, así como a modificar los Servicios y Contenidos del Sitio en cualquier momento y sin necesidad de previo aviso.
            El Usuario reconoce que no todos los Servicios y Contenidos están disponibles en todas las áreas geográficas y que algunos Servicios y Contenidos pueden ser utilizados solamente con posterioridad a su contratación, activación o registro previo por el Usuario y/o mediante el pago de estos. La Empresa no garantiza la disponibilidad y continuidad de la operación del Sitio y de los Servicios y Contenidos, ni la utilidad del Sitio o los Servicios y Contenidos en relación con cualquier actividad específica, independientemente del medio de acceso que utilice el Usuario.
    La Empresa no será responsable por daño o pérdida alguna de cualquier naturaleza que pueda ser causado debido a la falta de disponibilidad o continuidad de operación del Sitio y/o de los Servicios y Contenidos.
     El aprovechamiento de los Servicios y Contenidos en el Sitio, es exclusiva responsabilidad del Usuario, quien en todo caso deberá servirse de ellos acorde a las funcionalidades permitidas en el propio Sitio y a los usos autorizados en los presentes Términos y Condiciones, por lo que el Usuario se obliga a utilizarlos de tal modo que no contravengan las buenas costumbres, los derechos de terceros, las normas de uso y convivencia en Internet, las leyes de los Estados Unidos Mexicanos y, la legislación vigente en el país en que el Usuario se encuentre al acceder al Sitio y usar los Servicios y Contenidos. El Sitio es para el uso individual del Usuario por lo que no podrá comercializar de manera alguna los Servicios y Contenidos. 
    El Usuario se obliga a dejar en paz y a salvo a la Empresa, a sus accionistas, subsidiarias, afiliadas, funcionarios, directores, empleados, asesores, apoderados, representantes y/o cualquier persona relacionada con esta, respecto del uso de la información o documentación adquirida por parte del Usuario a través del Sitio, en la inteligencia de que la Empresa no es, ni será responsable de cualquier uso que el Usuario le dé a dicha información o documentación, ni por las consecuencias generadas o que pudieran generarse derivado de su uso. 
    </p>
    <h3>2. RESTRICCIONES. </h3>
    <p>El Usuario no tiene el derecho de colocar híper ligas dentro del Sitio, a utilizar las ligas del Sitio, ni el derecho de colocar o utilizar los Servicios y Contenidos en sitios o páginas propias o de terceros sin autorización previa y por escrito de la Empresa. El Usuario no podrá impedir a cualquier otro Usuario el uso del Sitio ni de los Servicios y Contenidos. </p>
        
    <h3>3. PROPIEDAD INTELECTUAL.</h3>
    <p>Los derechos de propiedad intelectual, derechos de autor, propiedad industrial respecto de los Servicios y Contenidos, los signos distintivos y dominios de las Páginas o del Sitio, así como los derechos de uso y explotación de estos, incluyendo de manera enunciativa más no limitativa, su divulgación, publicación, reproducción, distribución y transformación, son propiedad exclusiva de la Empresa. El Usuario no adquiere ningún derecho de propiedad intelectual, derechos de autor y/o propiedad industrial por el simple uso o acceso de los Servicios y Contenidos del Sitio y, en ningún momento, dicho uso será considerado como una autorización o licencia para utilizar los Servicios y Contenidos con fines distintos a los que se contemplan en los presentes Términos y Condiciones. </p>
        
     <h3>4. PROPIEDAD INTELECTUAL DE TERCEROS. </h3>
     <p>El Usuario acuerda que las disposiciones que se establecen en el artículo 3 tres anterior respecto de la titularidad de los derechos de la Empresa, también son aplicables a los derechos de terceros respecto de los Servicios y Contenidos de las Páginas, dominios o información presentada o vinculada al Sitio.</p>
        
    <h3>5. CALIDAD DE LOS SERVICIOS Y CONTENIDOS Y SUS GARANTÍAS.</h3>
    <p>Ni la Empresa, ni sus proveedores o socios comerciales, serán responsables de cualquier daño o perjuicio que sufra el Usuario a consecuencia de inexactitudes, consultas realizadas, errores tipográficos y cambios o mejoras que se realicen periódicamente a los Servicios y Contenidos y al Sitio. Los Servicios y Contenidos, recomendaciones y consejos obtenidos a través del Sitio son de naturaleza general, por lo que no deben tomarse en cuenta en la adopción de decisiones personales, comerciales ni profesionales. Para ello, se debe consultar a un profesional apropiado que pueda asesorar al Usuario de acuerdo con sus necesidades específicas. Los Servicios y Contenidos no son de carácter de asesoría ni consultoría legal y, no deben ni deberán de ser entendidos de dicha manera.
       La Empresa no es un despacho de abogados, únicamente ofrece herramientas y materiales a través del Sitio para la preparación de los Servicios y Contenidos ofrecidos. Todos los Servicios y Contenidos, incluyendo sin limitar, las descripciones, información, definiciones y cualquier otro recurso, son de carácter informativo y general en estricto sentido, no son de carácter de opinión ni asesoría legal, la Empresa no garantiza que sean correctos o se encuentren actualizados.
    La Empresa ofrece los Servicios y Contenidos con un nivel de competencia y diligencia razonable desde un punto de vista comercial, sin embargo, no ofrece ningún tipo de garantía en relación con estos. El Sitio es proporcionado por la Empresa “tal como está” y “según disponibilidad”. La Empresa no manifiesta ninguna representación o garantía de ningún tipo, expresa o implícita, en relación con la operación del Sitio, información, contenido, materiales, servicios o productos incluidos. El Usuario acepta expresamente que el uso del Sitio es bajo su propio riesgo. La Empresa se reserva el derecho a remover o eliminar cualquier información del Sitio, en cualquier momento, a su entera discreción. Ni la Empresa, ni sus proveedores, socios comerciales o distribuidores ofrecen garantías específicas sobre los Servicios y Contenidos; la Empresa excluye todas las garantías en la medida que las leyes vigentes lo permitan.
       De conformidad con los Términos y Condiciones, la Empresa no asume ni asumirá ningún tipo de responsabilidad frente a ninguna persona, derivada o que pudiera derivarse por los Servicios y Contenidos, navegación en el Sitio, consultas, aclaraciones y/o cualquier otra clase de respuesta otorgada por parte de la Empresa por cualquier medio de comunicación.
     El Usuario se obliga a dejar en paz y a salvo a la Empresa, a sus accionistas, subsidiarias, afiliadas, funcionarios, directores, empleados, asesores, apoderados, representantes y/o cualquier persona relacionada con esta, de cualquier responsabilidad que pudiera imputarse en virtud y/o en relación con el Sitio, la prestación de los Servicios y Contenidos o cualquier otro derivado de los presentes Términos y Condiciones.
     El Usuario entiende y acepta que la Empresa se encontrará limitada por responsabilidad de cualquier tipo, en todos los casos, al monto pagado como contraprestación por los Servicios y Contenidos. 
    </p>

    <h3>6. SUSCRIPCIONES.</h3>
    <p> La Empresa ofrece un servicio de suscripción personalizada que permite a los Usuarios tener acceso a diferentes Servicios y Contenidos de Suscripción, de conformidad con el Plan de Suscripción (como se define más adelante) que deseen (en lo sucesivo “Servicios y Contenido de Suscripción”). 
    El Usuario para tener acceso a los Servicios y Contenidos de Suscripción debe de contratar dentro del Sitio un Plan de Suscripción. Cada Plan de Suscripción es distinto entre sí, de manera enunciativa más no limitativa, en precio, funcionalidades disponibles, número de usuarios, entre otras características (en lo sucesivo “Plan de Suscripción”). 
    El Usuario para tener acceso a los Servicios y Contenido de Suscripción debe proporcionar a la Empresa una forma de pago. 
    La Empresa puede realizar tantas variaciones como lo desee en los Planes de Suscripción, a su entera discreción.
    </p>
        
    <h3>7. PAGOS.</h3>
    <p>La Empresa a su entera discreción podrá cambiar el precio de cada uno de los Servicios y Contenidos dentro del Sitio en cualquier momento. 
    El pago de los Servicios y Contenidos puede realizarse por medio de los siguientes métodos de pago:</p>
        
    <h4>7.1 Pagos por medio de la plataforma/pasarela de pagos Stripe, Inc. (en adelante “Stripe”):</h4>
    <p> la Empresa por medio de Stripe realizará cargos a la tarjeta de débito o crédito del Usuario, previa autorización, por concepto de pago de los Servicios o Contenidos, cargos a meses sin intereses, cargos de contado o 
    cargos recurrentes. El Usuario reconoce y acepta que Stripe se reserva el derecho de declinar una transacción por cualquier motivo y, que, en su caso, ni Stripe ni la Empresa serán responsables de dichos pagos declinados. Será Stripe quien recabe y procese directamente el pago, por lo que toda la captura de información de la tarjeta de crédito o débito del Usuario se lleva a cabo en dicha plataforma y servidores del procesador de Stripe, bajo sus propios estándares de seguridad. En ningún momento la Empresa solicitará al Usuario información que NO resulte necesaria para su vinculación con Stripe para la facilitación del pago, por lo que los datos de tarjetas débito o crédito, solo se solicitarán al momento de realizar el pago virtual si así lo determina el Usuario, estos datos no serán almacenados por la Empresa, serán inscritos directamente en la plataforma de Stripe. El Usuario autoriza enviar instrucciones a la entidad financiera que emitió su tarjeta para que realice los pagos con cargo a su cuenta de tarjeta de acuerdo con las condiciones de los presentes Términos y Condiciones. El Usuario al utilizar el Sitio reconoce y acepta sin reservas los Términos y Condiciones, así como el Aviso de Privacidad de Stripe, dejando a la Empresa libre de cualquier responsabilidad y reclamación por el manejo de su información.
    </p>

    <h4>7.2 PAGO DE SUSCRIPCIONES.</h4>
    <p>Los Usuarios pueden adquirir Servicios y Contenidos mediante el pago de una Suscripción. El Usuario podrá escoger entre realizar un cargo periódico de manera mensual por adelantado para utilizar los Servicios y Contenido de Suscripción o, en su caso, un pago anual por adelantado para utilizar los Servicios y Contenidos de Suscripción por un periodo de 12 meses contados a partir del día del pago realizado.
      En caso de que la Empresa cambie el precio de los Servicios y Contenidos de Suscripción, informará a los Usuarios que cuenten con Servicios y Contenidos de Suscripción pagadas con anticipación a la entrada en vigor del nuevo precio. Los cambios de precio entrarían en vigor al comienzo del próximo periodo de suscripción. Si el Usuario no estuviera de acuerdo con el nuevo precio, deberá cancelar su suscripción cuanto antes. De conformidad con la ley aplicable, el Usuario acepta el nuevo precio al continuar con el uso de los Servicios y Contenidos después de que el cambio de precio entre en vigor.
    </p>
        
         <h4>7.3 Ciclo de facturación:</h4>
         <p>Los cargos por el Plan de Suscripción que permite el uso de los Servicios y Contenidos de Suscripción por los servicios que ofrece la Empresa, así como cualquier otro cargo que se incurra, se cobrarán a la forma de pago indicada por el Usuario en la fecha de facturación específica a la contratación del Plan de Suscripción. 
             La duración del ciclo de facturación dependerá del tipo de suscripción que haya seleccionado el Usuario al momento de la contratación de los Servicios y Contenidos de Suscripción. 
         </p>
         <h4> 7.4  </h4>
         <p>El Usuario al adquirir un Paquete Suscripción para el uso de los Servicios y Contenido de Suscripción autoriza a la Empresa, por medio de Stripe, a realizar cargos automáticos de manera mensual o anual,
          según su elección, hasta que el Usuario o la Empresa cancele el paquete de Servicios y Contenidos de Suscripción. 
        </p>
        
         <h4>7.5 </h4>
         <p>El Usuario autoriza a la Empresa a renovar de manera automática su Plan de Suscripción al final de cada periodo de suscripción, a menos que cancele su Servicios y Contenido de Suscripción previo al final del periodo. El Usuario puede cancelar su Plan de Suscripción en cualquier momento y, continuará teniendo acceso a los Servicios y Contenidos de Suscripción hasta el final de su periodo de suscripción. La cancelación del paquete de Servicios y Contenido de Suscripción entrará en vigor al día siguiente al último día del periodo. </p> 
        
         <h4>7.6</h4> 
         <p>El Usuario acepta y reconoce que la cancelación de su Plan de Suscripción no significa el reembolso de ninguna cantidad previamente pagada.
             Si el Usuario o la Empresa rescinde cualquier relación, o la Empresa suspende el acceso del Usuario a los Servicios y Contenidos, Servicios y Contenidos de Suscripción y/o su Plan de Suscripción, el Usuario acepta que la Empresa no tendrá ninguna responsabilidad y obligación hacia el Usuario y, la Empresa no reembolsará ningún monto que haya pagado, en el mayor grado permitido por la ley aplicable. 
             El Usuario puede cancelar su Plan de Suscripción en cualquier momento. Esta y todas las secciones se harán valer en la medida permitida por la ley aplicable. 
         </p>    
        
         <h4>7.7 DEVOLUCIONES Y CANCELACIONES. </h4>
         <p>El Usuario reconoce que los cargos que se realicen a tarjetas de débito o crédito, así como los pagos realizados mediante SPEI (transferencia electrónica o pago en efectivo utilizando el recibo generado para ello), con motivo de los Servicios y Contenidos y/o los Servicios y Contenidos de Suscripción, no serán objeto de devolución y, que al realizar dichos pagos se sujeta y acepta sin reservas a los Términos y Condiciones y Aviso de Privacidad de los proveedores de servicios relacionados con dichos pagos. En la medida que la ley aplicable lo permita, ningún pago realizado será reembolsable y no se otorgarán reembolsos ni créditos por la Empresa. </p>
        
         <h3>8. FACTURACIÓN.</h3> 
         <p>Los Usuarios recibirán una o varias facturas correspondientes a los Servicios contratados y que serán emitidas por la Entidad con motivo de los Servicios y Contenidos y/o los Servicios y Contenidos de Suscripción. Los Usuarios podrán solicitar la emisión de su factura electrónica CFDI (archivos .xml y .pdf) debidamente timbrada a más tardar 30 días naturales posteriores al pago de los Servicios y Contenidos. El Usuario deberá de proveer a la Empresa los siguientes datos: la (i) nombre, denominación o razón social, (ii) domicilio fiscal, (iii) Registro Federal de Contribuyentes (RFC) y (iv) correo electrónico.</p>
        
         <h3>9. BIENES Y SERVICIOS DE TERCEROS ENLAZADOS.</h3>
         <p>El hecho de que se ofrezca información en el Sitio o en otros sitios ligados o vinculados, no implica la recomendación, garantía, patrocinio o aprobación por parte de la Empresa respecto de dicha información, bienes y/o servicios. La disponibilidad de bienes y/o servicios ofertados por terceros o por sitios ligados o vinculados, no es responsabilidad de la Empresa. En virtud de lo anterior, la Empresa no será responsable ante cualquier autoridad de cualquier naturaleza, por cualquier asunto relacionado con la venta, consumo, distribución, entrega, disponibilidad o prestación con respecto de cualquiera de los bienes y/o servicios ofertados por terceros o por sitios ligados o vinculados a través del Sitio.
             Respecto de los Servicios y Contenidos que prestan terceros dentro o mediante enlaces al Sitio (tales como ligas, banners y botones), la Empresa se limita exclusivamente, para conveniencia del Usuario, a: (i) informar al Usuario sobre los mismos y, (ii) a proporcionar un medio para poner en contacto al Usuario con proveedores o vendedores. Los productos y/o servicios que se comercializan dentro del Sitio y/o en los sitios de terceros enlazados son suministrados por comerciantes independientes y no se entenderá en ningún caso que son responsabilidad de la Empresa. No existe ningún tipo de relación laboral, asociación o sociedad, entre la Empresa y dichos terceros. Toda asesoría, consejo, declaración, información y contenido de las páginas de terceros enlazadas o dentro del Sitio representan las opiniones y juicios de dicho tercero, consecuentemente, la Empresa no será responsable de ningún daño o perjuicio que sufra el Usuario a consecuencia de estos.</p> 

         <h3>10. CONFIDENCIALIDAD.</h3> 
         <p>La Empresa se obliga a mantener confidencial la información que reciba del Usuario que tenga dicho carácter conforme a las disposiciones legales aplicables en los Estados Unidos Mexicanos; la Empresa no asume ninguna obligación de mantener confidencial cualquier otra información que el Usuario le proporcione. 
             La Empresa podrá divulgar la información del Usuario y de los Servicios y Contenidos adquiridos por éste a aquellos terceros que, en virtud de los servicios y productos ofrecidos y adquiridos, necesiten conocerlos para cumplir cabalmente con los mismos.  
             Asimismo, la Empresa puede divulgar su información a las autoridades competentes en términos de la legislación aplicable; cualquier transferencia de sus datos personales sin consentimiento se realizará de acuerdo con el Artículo 37 de la LFPDPPP.</p>

         <h3>11. USO DE LA INFORMACIÓN NO CONFIDENCIAL. </h3>
         <p>Mediante el uso del Sitio, el Usuario autoriza a la Empresa, de manera enunciativa más no limitativa, a utilizar, publicar, reproducir, divulgar, comunicar públicamente y transmitir la información no confidencial, en términos de lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, en la Ley Federal de los Derechos de Autor, en la Ley Federal de Protección al Consumidor y en cualquiera otra aplicable en la legislación mexicana.</p> 
        
         <h3>12. COOKIES.</h3> 
         <p>El Usuario que tenga acceso al Sitio, conviene en recibir archivos que les transmitan los servidores de la Empresa.  Una “Cookie” es un archivo de datos que se almacena en el disco duro de la computadora del Usuario cuando éste acceda al Sitio. Dichos archivos pueden contener información tal como la identificación proporcionada por el Usuario o información para rastrear las páginas que el Usuario ha visitado. Una Cookie no puede leer los datos o información del disco duro del Usuario ni leer las Cookies creadas por otros sitios o páginas.
             Generalmente, las Cookies son aceptadas automáticamente, el Usuario puede cambiar la configuración de su navegador en cualquier momento.  En caso de que el Usuario decida rechazar las Cookies, es posible que ciertas secciones del Sitio no tengan su funcionamiento óptimo o incluso no funcionen en absoluto.</p>

         <h3>13. AVISO DE PRIVACIDAD DE DATOS PERSONALES.</h3>
         <p>Toda la información que la Empresa recabe del Usuario es tratada con absoluta confidencialidad conforme las disposiciones legales aplicables en la legislación mexicana.
         Para conocer más sobre la protección de sus datos personales por favor consulte nuestro Aviso de Privacidad. </p> 
        
         <h3>14. CLAVES DE ACCESO.</h3>
         <p> En todo momento, el Usuario es el responsable único y final de mantener en secreto las claves de acceso que pudiera tener y con las cuales tuviera acceso a ciertos Servicios y Contenidos del Sitio. </p>
        
         <h4>14.1 CUENTAS.</h4>
         <p>El Usuario, al crear una cuenta en el Sitio, declara, bajo protesta de decir verdad, que tiene como mínimo 18 años o la mayoría de edad legal en su jurisdicción; el Usuario reconoce que es su responsabilidad cualquier actividad que se desarrolle con dicha cuenta o a través de ella y reconoce que tiene conocimiento y acepta las condiciones establecidas en los presentes Términos y Condiciones y en el Aviso de Privacidad. </p> 

         <h3>15. MODIFICACIONES.</h3> 
         <p>La Empresa tendrá el derecho de modificar, en cualquier momento, los Términos y Condiciones, sin previo aviso y/o consentimiento del Usuario. En consecuencia, el Usuario debe leer atentamente los Términos y Condiciones cada vez que pretenda utilizar el Sitio. Ciertos Servicios y Contenidos ofrecidos a los Usuarios en y/o a través del Sitio están sujetos a condiciones particulares propias que sustituyen, completan y/o modifican los presentes Términos y Condiciones. Consiguientemente, el Usuario también debe leer atentamente las correspondientes condiciones particulares antes de acceder a cualquiera de los Servicios y Contenidos.</p>
        
         <h3>16. LEYES APLICABLES Y JURISDICCIÓN.</h3>
         <p>Para la interpretación, cumplimiento y ejecución de los presentes Términos y Condiciones, el Usuario está de acuerdo en que serán aplicables las leyes Federales de los Estados Unidos Mexicanos y competentes los tribunales de la Ciudad de México, renunciando expresamente a cualquier otro fuero o jurisdicción que pudiera corresponderles debido a sus domicilios presentes o futuros o por cualquier otra causa.
             Si alguna de las disposiciones de los presentes Términos y Condiciones es declarada nula, ilegal o inaplicable, la validez, legalidad y aplicación de las restantes disposiciones continuarán en plena vigencia.
         </p> 
        
         <h3>17. CONTACTO. </h3>
         <p>El Usuario puede contactar, en todo momento, al personal de la Empresa para cualquier aclaración, comentario, duda y/o sugerencia relacionada con los Servicios y Contenidos, con el Sitio y/o con los presentes Términos y Condiciones vía correo electrónico a info@maind.io</p>
    
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
                {/* End Terms and Conditions Area  */}
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}
export default TermsConditions;
