import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { t } from 'i18next';

const SlpitOne = () => {
    return (
            <div className="rn-splite-style">
                <div className="split-wrapper">
                        <div className="row no-gutters radius-10 align-items-center">
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="thumbnail image-left-content">
                                    <img src="./images/split/maindthegap.png" alt="split Images" />
                                </div>
                            </div>
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="split-inner">
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        {/* <h4 className="title">What is DOOB?</h4> */}
                                        <h4 className="title">MAIND THE GAP</h4>
                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <p className="description">{t("Welcome to MAIND LOGICS, where we redefine legal tech. Our user-oriented approach, relentless pursuit of innovation, exceptional customer service, and dedication to maximizing value shape our core values. Our document automation platform is highly adaptable, flexible, and user-friendly, empowering our clients to be exceptionally effective in drafting legal documents. Join us to experience the future of legal innovation.")}
                                    </p>
                                    </ScrollAnimation>

                                    
                                    {/* <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <ul className="split-list">
                                            <li>- Doug DeMarco, Design Prototyping Manager</li>
                                            <li>- 108 million paying subscribers</li>
                                            <li>- Over 1.7 billion hours of music played monthly</li>
                                            <li>- 4,000+ employees working across 16 offices</li>
                                        </ul>
                                    </ScrollAnimation> */}
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <div className="view-more-button mt--35">
                                            <a className="btn-default" href="/contact">{t('Contact With Us')}</a>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default SlpitOne
