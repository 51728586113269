import React from 'react';
import BookDemoForm from "./BookDemoForm";
import { FiHeadphones , FiMail , FiMapPin, FiPhoneCall } from "react-icons/fi";

const BookDemoOne = () => {
    return (
        <>
            <div className="row mt--40 row--15 justify-content-center">
                <div className="col-lg-7">
                    <BookDemoForm formStyle="contact-form-1" />
                </div>
                {/* <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <GoogleMapStyle />
                </div> */}
            </div>
        </>
    )
}
export default BookDemoOne;