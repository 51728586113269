import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";
import { t } from 'i18next';

const Data = [
    {
        countNum : 75,
        type: 'count-percent',
        countTitle: t('Faster Workflow'),
    },
    {
        countNum : 1500,
        type: 'count-number',
        countTitle: t('Docs in our Corpus'),
    },
    {
        countNum : 60,
        type: 'count-percent',
        countTitle: t('Faster Contract Drafting'),
    },
    {
        countNum : 1220,
        type: 'count-number',
        countTitle: t('Hours of enhanced productivity'),
    },
];
const SlipFour = () => {
    return (
        <div className="rn-splite-style bg-color-blackest">
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                    <div className="order-2 order-lg-1 col-lg-12 col-xl-6 col-12">
                        <div className="split-inner">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                {/* <h4 className="title">Continue Your Business With Us.</h4> */}
                                <h4 className="title">{t('Craft your legal AI with MAIND.')}</h4>
                            </ScrollAnimation>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">{t("MAIND LOGICS empowers lawyers with advanced generative AI technology. Our tailored service delivers custom large language models, finely tuned to their unique needs. We understand the ever-evolving demands of the legal field and are dedicated to crafting AI solutions that enhance research, automation, and more. Our state-of-the-art AI equips legal professionals to draft documents and streamline their workflow. Partner with us to harness AI's full potential in the legal domain, gaining a competitive edge in today's fast-paced legal landscape.")}</p>
                            </ScrollAnimation>

                            <div className="row">
                                {Data.map((data, index) => (
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                        <div className="count-box counter-style-4 text-start">
                                            <TrackVisibility once>
                                                {({ isVisible }) => isVisible && 
                                                    <div className={data.type}>{isVisible ? <CountUp end={data.countNum} /> : 0}</div>}
                                            </TrackVisibility>
                                            <h5 className="counter-title">{data.countTitle}</h5>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="order-1 order-lg-2 col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail">
                            <img src="./images/split/craftmaind.png" alt="split Images" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SlipFour;